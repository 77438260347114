export default {
    props: {
        //题目ID
        subjectId: {
            type: Number | String,
            default: 0,
        },
        //题目
        subject: {
            type: Object,
            default: () => { },
        },
        //结束语
        peroration: {
            type: String,
            default: "",
        },
        //开始状态
        state: {
            type: Boolean,
            default: false,
        },
        is_from: {
            type: Number,
            default: 1,
        },
        //数据
        appName: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            loading: false, //加载中
            activityId: 0, //活动id
            linkId: 0, //环节id
            subjectList: [], //题目列表
        };
    },
    created() {
        this.linkId = this.$route.query.id;
        this.activityId = this.$route.query.activityId;
        this.intSubject();
    },
    mounted() { },
    methods: {
        // //问卷
        getsid(val) {
            console.log(val, "数据选中");
        },
        //初始化题目
        intSubject() {
            console.log(this.subject, 'this.subject');
            this.subject.topics[1].forEach((item) => {
                /**选择题 单选 */
                if (item.type == 1 && item.selected_type == 1) {
                    item.option_id = 0;
                    item.options.forEach((item1) => {
                        item1.option_content = "";
                    });
                }
                /**选择题 多选 */
                if (item.type == 1 && item.selected_type == 2) {
                    item.options.forEach((item1) => {
                        item1.select = false;
                        item1.option_content = "";
                    });
                }
                /**填空题、排序题 */
                if (item.type == 2 || item.type == 3) {
                    item.options.forEach((item1) => {
                        item1.option_content = "";
                    });
                }
                /**量表题 */
                if (item.type == 4) {
                    if (item.selected_type == 1 || item.selected_type == 3) {
                        item.lines.forEach((item1) => {
                            item1.option_id = 0;
                            item1.option_content = "";
                        });
                    }
                    //多远矩阵
                    if (item.selected_type == 2) {
                        // item.options.forEach((item1) => {
                        //     this.$set(item1,"select",false)
                        // });
                        item.lines.forEach((item1) => {
                            //设置选项
                            this.$set(item1, "selectOption", item.options)
                            //设置选中的
                            this.$set(item1, "select", [])
                            console.log(item1, "测试问题");
                        });

                    }
                }
            });

            this.subjectList = JSON.parse(JSON.stringify(this.subject.topics[1]));
            this.loading = true;
        },
        //排序上移
        onUpItem(index, items, index1) {
            if (index1 != 0) {
                this.subjectList[index].options.splice(index1 - 1, 0, items);
                this.subjectList[index].options.splice(index1 + 1, 1);
            }
        },
        //排序下移
        onDownItem(index, items, index1) {
            this.subjectList[index].options.splice(index1 + 2, 0, items);
            this.subjectList[index].options.splice(index1, 1);
        },
        //提交
        async submit() {
            let answer = [];
            let incompleteArray = []; //未完成题目
            let status = true;
            let subjectList = JSON.parse(JSON.stringify(this.subjectList));

            subjectList.forEach((item, index) => {
                item.status = true;
            });

            subjectList.forEach((item, index) => {
                let params = {
                    topic_id: item.id,
                    selected: [],
                };
                /**选择题 单选 */
                if (item.type == 1 && item.selected_type == 1) {
                    if (item.is_required == 2) {
                        if (!item.option_id) {
                            item.status = false;
                        }
                    }
                    item.options.forEach((item1) => {
                        if (
                            item1.id == item.option_id &&
                            item1.fill_in_required == 2 &&
                            !item1.option_content
                        ) {
                            item.status = false;
                        }
                        if (item1.id == item.option_id) {
                            params.selected.push({
                                option_id: item.option_id,
                                option_content: item1.option_content,
                            });
                        }
                    });
                    answer.push(params);
                }
                /**选择题 多选 */
                if (item.type == 1 && item.selected_type == 2) {
                    let isSelect = false;
                    item.options.forEach((item1) => {
                        if (item1.select) {
                            isSelect = true;
                            params.selected.push({
                                option_id: item1.id,
                                option_content: item1.option_content,
                            });
                        }
                        if (
                            item1.select &&
                            item1.fill_in_required == 2 &&
                            !item1.option_content
                        ) {
                            item.status = false;
                        }
                    });
                    if (item.is_required == 2) {
                        if (!isSelect) {
                            item.status = false;
                        }
                    }
                    answer.push(params);
                }
                /**填空题*/
                if (item.type == 2) {

                    item.options.forEach((item1) => {
                        if (item.is_required == 2 && !item1.option_content) {
                            item.status = false;
                        }

                        params.selected.push({
                            option_id: item1.id,
                            option_content: item1.option_content,
                        });
                    });
                    answer.push(params);
                }

                /**排序题 */
                if (item.type == 3) {
                    item.options.forEach((item1) => {
                        if (item1.fill_in_required == 2 && !item1.option_content) {
                            item.status = false;
                        }

                        params.selected.push({
                            option_id: item1.id,
                            option_content: item1.option_content,
                        });
                    });
                    answer.push(params);
                }

                /**量表题 */
                if (item.type == 4) {
                    if (item.selected_type == 1 || item.selected_type == 3) {
                        item.lines.forEach((item1, index1) => {
                            if (item.is_required == 2) {
                                if (!item1.option_id) {
                                    item.status = false;
                                }
                            }

                            item.options.forEach((item2, index2) => {
                                if (
                                    item1.option_id == item2.id &&
                                    item2.fill_in_required == 2 &&
                                    !item1.option_content
                                ) {
                                    item.status = false;
                                }

                                if (item1.option_id == item2.id) {
                                    params.selected.push({
                                        option_id: item1.option_id,
                                        option_content: item1.option_content,
                                        line_id: item1.id,
                                    });
                                }
                            });
                        });
                    }
                    //多选矩阵
                    if (item.selected_type == 2) {
                        let isSelect = false;
                        item.lines.forEach((t) => {
                            //先判断改题是不是必填选,同时选中选项
                            if (item.is_required == 2 && t.select.length != 0) {
                                isSelect = true;
                                t.select.forEach((a) => {
                                    //获取下标
                                    let idx1 = t.selectOption.findIndex((b) => { return b.id == a });
                                    //赋值
                                    let x = t.selectOption[idx1];

                                    if (x.allow_fill_in == 1) { //没有填空
                                        let ob1 = {
                                            option_id: a,
                                            line_id: t.id,
                                        }
                                        params.selected.push(ob1);
                                    } else if (x.allow_fill_in == 2) { //填空

                                        let flat = x.hasOwnProperty('option_content');
                                        //判断选项中是否含有填空内容
                                        if (x.fill_in_required == 2 && !flat) { //判断是否必填
                                            item.status = false;
                                        }

                                        //填空有内容
                                        if (flat) {
                                            let ob1 = {
                                                option_id: a,
                                                option_content: x.option_content,
                                                line_id: t.id,
                                            }
                                            params.selected.push(ob1);
                                        } else {

                                            //填空没内容
                                            let ob1 = {
                                                option_id: a,
                                                line_id: t.id,
                                            }
                                            params.selected.push(ob1);
                                        }
                                    }
                                    // console.log(params,"选中");
                                })
                            }
                            //选项内容  选项id  行id
                        })


                        if (item.is_required == 2) {
                            if (!isSelect) {
                                item.status = false;
                            }
                        }
                        console.log(item, "提交的数据11");
                    }
                    // if (item.selected_type == 2) {
                    //     let isSelect = false;
                    //     item.lines.forEach((item1, index1) => {
                    //         item.options.forEach((item2) => {
                    //             if (item2.select) {
                    //                 isSelect = true;

                    //                 if (item2.fill_in_required == 2 && !item2.option_content) {
                    //                     item.status = false;
                    //                 }

                    //                 params.selected.push({
                    //                     option_id: item2.id,
                    //                     option_content: item1.option_content,
                    //                     line_id: item1.id,
                    //                 });
                    //             }
                    //         });
                    //     });
                    //     if (item.is_required == 2) {
                    //         if (!isSelect) {
                    //             item.status = false;
                    //         }
                    //     }
                    // }
                    answer.push(params);
                }
            });

            subjectList.forEach((item, index) => {
                //判断是否有题没有完成
                if (!item.status) {
                    status = false;
                    incompleteArray.push(index + 1);
                }
            });
            if (!status) {
                this.$message({
                    message: `第 ${incompleteArray.join("、")} 题还有没完成`,
                    type: "warning",
                });
                return false;
            }



            //确认提交问卷请求
            this.$confirm(`是否确定提交本次${this.appName}?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let p = null
                if (this.is_from == 1) {
                    p = this.$axios
                        .post(
                            `activity/${this.activityId}/activityLink/${this.linkId}/questionnaire/${this.subjectId}`,
                            { answer }
                        )
                } else {
                    p = this.$axios
                        .post(
                            `TeachingStudioactivity/${this.activityId}/activityLink/${this.linkId}/questionnaire/${this.subjectId}`,
                            { answer, teaching_studio_id: localStorage.getItem("studioId") }

                        )
                }
                p.then((res) => {

                    //结束语的话
                    if (res.data.code == 900) {
                        let str = '';
                        //正则
                        var pattern = /<[^>]+>/g;
                        str = this.subject.submit_doc.match(pattern) && this.subject.submit_doc.match(pattern).length > 0 ? this.subject.submit_doc.replace(/<[^>]+>/g, "") : this.subject.submit_doc;
                        this.$message.success(str)
                        // if (this.peroration) {
                        //     this.$alert(this.peroration, "", {
                        //         dangerouslyUseHTMLString: true,
                        //     });
                        // } else {
                        //     this.$message({
                        //         type: "success",
                        //         message: res.data.msg,
                        //     });
                        // }





                        this.$emit("onSubmit", res.data);
                    }
                });
            });
        },
    },
    watch: {},
    filters: {
        //题型过滤
        subjectType(type, single) {
            if (type == 1) {
                if (single == 1) {
                    return "单选题";
                } else {
                    return "多选题";
                }
            }
            if (type == 2) {
                return "填空题";
            }
            if (type == 3) {
                return "排序题";
            }
            if (type == 4) {
                return "量表题";
            }
        },
    },
};
